.approvement-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 2.5rem;

  &-preview-image {
    max-height: 90vh;
    max-width: 90vw;
  }

  .status-icon__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    svg path {
      stroke: $c-red;
    }

    &.active {
      svg path {
        stroke: $c-green;
      }
    }
  }

  .approvement-child-row {
    display: flex;
    padding: 1rem 0rem 1rem;
    border-bottom: 0.125rem solid $c-gray-80;
    margin-bottom: 1rem;

    .imgWrapper {
      display: flex;
      align-items: center;

      min-width: 28rem;
      width: 28rem;
      height: 28rem;
      border-radius: 0.75rem;
      overflow: hidden;

      .__approvement_img {
        width: 100%;
        max-width: 20rem;
      }
    }

    .MuiInputBase-root {
      height: 4rem;
      width: 20rem;
    }

    .table {
      .MuiInputBase-root,
      .MuiFormControl-root {
        height: 4rem;
        width: 100%;
      }
    }
  }

  .study-child-table {
    margin: -1.5rem -2.5rem;

    .table,
    .table-header-row {
      background: none;
    }
    .table-row:first-child {
      border-radius: 0.75rem 0.75rem 0 0;
    }
    .table-row:last-child {
      border-radius: 0 0 0.75rem 0.75rem;
    }
  }
  .merch-table {
    .table .table-header-row {
      background: none;
      border-bottom: 0.125rem solid $c-gray-80;
      .table-header__item {
        font-weight: 400;
      }
    }
    .table .table-children-row {
      padding: 0;
    }

    .table-header__item:nth-child(5),
    .table-row__cell:nth-child(5) {
      border-left: 0.125rem solid #ececec;
      padding-left: 3.75rem;
    }
  }
}
.approve-merch-table_header {
  background: linear-gradient(
    281.11deg,
    #b3cdfe 0%,
    #b3cefe 0.01%,
    #bfe9fc 100%
  );
  height: 4.5rem;
  display: flex;
  align-items: center;
  &__item {
    @extend .sm-text;
    font-weight: bold;
    flex: 45%;
    text-align: center;
  }
}
